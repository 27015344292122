.home1 {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-style: italic;
	line-height: 175%;
	margin-top: 35px;
	}
.home2 {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 15px;
	font-weight: bold;
	line-height: 125%;
	}
.blueBox {
	background-color: #272265;
	width: 100%;
	height: 10px;
	}
.slides {
	background-color: white;
	width: 100%;
	height: 270px;
	overflow: hidden;
	}
.blueNav {
	background-color: #272265;
	width: 100%;
	height: 50px;
	}
.arrow ul {
	margin: 0 auto;
	padding: 0;
	float: left;
	}
.arrow ul li {
	text-decoration: none;
	color: #272265;
	padding: 0 0 0 20px;
	background:url(/images/arrow.png) center left no-repeat;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	display: block;
	}
.arrow ul li a a:hover{
	text-decoration: none;
	color: #170a54;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	display: inline-block;
	padding: 0;
	margin: 0 auto;
	}

.menu {
	width: 100%;
	font-family:Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	padding: 0;
	}

.menu ul {
	margin:0;
	padding:0;
	margin-left:42px;
	#margin-left:23px;
	list-style-type:none;
	text-align:center;
}

.menu ul li { display:inline; }

.menu ul li a
{
	text-decoration:none;
	padding:18px 20px 18px 25px;
	font-weight:bold;
	float:left;
	margin:0px auto;
	color:#ffffff;
	background-color: #272265;
        background:url("/images/bullet_home.gif") no-repeat left;
}

.menu ul li a:visited
{
	color:#ffffff;
	background-color:#272265;
	text-decoration:none;
}

.menu ul li a:hover, .menu ul li .current
{
	color:#ffffff;
	background-color:#170a54;
        background-image:url("/images/bullet_home_over.gif");
}


#pl {
	position:relative;
	top: 0px;
	width: 777px;
	height: 270px;
	background-image: url(/images/headers/insurance_securities.jpg);
	overflow: hidden;
	}
#ce {
	position:relative;
	top: 0px;
	width: 777px;
	height: 270px;
	overflow: hidden;
	background-image: url(/images/headers/insurance_ce.jpg);
	}
#securities {
	position:relative;
	top: 0px;
	width: 777px;
	height: 270px;
	overflow: hidden;
	background-image: url(/images/headers/securities_licensing.jpg);

	}
#fr {
	position:relative;
	top: 0px;
	width: 777px;
	height: 270px;
	overflow: hidden;
	background-image: url(/images/headers/fraternals.jpg);

	}
#fe {
	position:relative;
	top: 0px;
	width: 777px;
	height: 270px;
	overflow: hidden;
	background-image: url(/images/headers/firm_element.jpg);
	}
#ad {
	position:relative;
	top: 0px;
	width: 777px;
	height: 270px;
	overflow: hidden;
	background-image: url(/images/headers/advanced_designation.jpg);
	}
.boxoverStyles {
	float:right;
	margin: 10px 10px 10px 10px;
	width: 275px;
	}
#topslide {
	position:relative;
	top: 0px;
	width: 777px;
	height: 270px;
	overflow: hidden;
	background-image: url(/images/home_img.jpg);
	}

.kfe_login_users_tables {
	margin-left: 15px;
}

#row8 > td > h2 {
  padding-left: 15px;
}
